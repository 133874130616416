@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

#root {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0px 0 00px 0;
  /* Header height and footer height */
  margin: 0 auto;
  /* Center content */
}
.react-calendar__navigation button {
  font-size: 1em;
}

.react-calendar {
  background-color: transparent;
  background: transparent;
}

/* .fc .fc-timegrid-slots {
  background: #dcdcdc;
}   */

.fc-timegrid-slot {
  line-height: 100px;
}
.fc .fc-timegrid-slot-label {
  background: #ececec;
  border-color: white;
}
.fc .fc-timegrid-slot {
  height: 1.5em;
  border-bottom: 0;
  border-color: darkgrey;
  /* each cell owns its top border */
}

.fc-timegrid-event.fc-event-mirror {
  box-shadow: 0px 0px 0px 0px #fff;
  box-shadow: 0px 0px 0px 0px var(--fc-page-bg-color, #fff);
}
.fc-timegrid-event {
  /* events need to be root */

  font-size: 0.85em;

  font-size: var(--fc-small-font-size, 0.85em);
  border-radius: 3px;
}

.fc-media-screen .fc-timegrid-event {
  position: absolute;
  top: 0;
  bottom: 1px; /* stay away from bottom slot line */
  left: 0;
  right: 0;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 0% 0 -1px;
}

/* .fc-timegrid-event-harness{
  border: none
} */

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  background-color: red !important;
  width: 50px !important;
  border-top-color: red !important;
  border-bottom-color: red !important;
  margin-top: 0px !important;
  border-width: 2px !important;
}
.fc .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  width: 50px !important;
  background-color: red !important;
  border-top-color: red !important;
  border-bottom-color: red !important;
  margin-top: 0px !important;
  border-width: 2px !important;
}
.fc .fc-timegrid-now-indicator-line {
  background-color: red !important;
  border-width: 2px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: ; */
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  margin: 0; 
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 5px !important;
}

.nav-selected {
  background-color: #4543d9;
  color: white;
}

.MuiAlert-outlinedInfo {
  background-color: white !important;
  border-color: transparent !important;
}

.fa-icon {
  color: #4543d9;
}


#crumbs {
    text-align: center;
  }
  
  #crumbs ul {
    list-style: none;
    display: inline-table;
  }
  
  #crumbs ul li {
    display: inline;
  }
  
  #crumbs ul li{
    display: block;
    float: left;
    height: 26px;
    background:  #908ef7;
    text-align: center;
    padding: 1px 20px 6px 20px;
    position: relative;
    margin: 0 5px 0 0;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
  }
  
  #crumbs ul li:after {
    content: "";
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 15px solid  #908ef7;
    position: absolute;
    right: -15px;
    top: 0;
    z-index: 1;
  }

  #crumbs ul li:before {
    content: "";
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 15px solid ;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  #crumbs ul li:first-child{
    border-top-left-radius: 02px;
    border-bottom-left-radius: 02px;
  }
  
  #crumbs ul li:first-child:before {
    display: none;
  }
  
  #crumbs ul li:last-child {
   
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  #crumbs ul li:last-child:after {
    display: none;
  }
  
  .controls {
    margin-bottom: 2em;
  
    label {
      margin-right: 1em;
      margin-bottom: 0.25em;
      margin-top: 1em;
      min-width: 10em;
      display: block;
    }
  
    input[type='range'] {
      width: 20em;
    }
  
    select {
      width: 20em;  
      display: inline-block;
      margin-right: 1em;
    }
    .fontsize_control {
        display: flex;
        flex-direction: row;
    
        button {
          display: flex;
          margin: 8px 0;
          min-width: 24px;
          height: 24px;
          text-align: center;
          line-height: 16px;
        }
        p {
          margin: 8px;
          height: 24px;
          display: flex;
        } 
      }
  }
  
  
  
  #crumbs ul li.selected{
     background: #4543d9;
  }
  
  #crumbs ul li.selected::after{
      border-left: 15px solid  #4543d9;
  }
  
  
  .pipWindow {
   
    z-index: 100;
    position: fixed; 
    box-sizing: content-box;
    padding: 0px;
    margin: 0px;
    box-sizing: content-box;
    box-shadow: 1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%);
    border-radius: 0.28571429rem;
    border: #d9d9fe 1px solid;

   
  }
  
  .pipWindow:hover {
    cursor: grab;
  }
  
  .pipWindow:active {
    cursor: grabbing;
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }

  .pipResizableContent {
   
    border-radius: 0.28571429rem;
   
  }
  
  .pipResizableContent:hover {
    cursor: default;
  }


  
  
   @media print {
   
    @page {
        
      size: A4;
      margin-top: 5mm;
      margin-bottom: 8mm ;
     
      
    }
   
    
    ::-webkit-scrollbar {
        display: none;
    }
    header { 
        /* position: fixed;  */
        top: 0; 
        left: 0; 
        right: 0; 
        height: 230x !important; 
      
     
    } 
    .extra-spacing-tr {
        height: 20px !important;
    }

    footer { 
        
        /* position: fixed ; */
        bottom: 0; 
        left: 0; 
        right: 0; 
        height:  40px !important; 
       
      
        
    } 
   
    main {
        /* display: flex; */
        /* align-content: flex-end;
        justify-content: center;
       flex-direction: column; */
        /* margin-top: 210px !important; */
      
      
        /* flex-wrap: wrap;
        flex: 1 1 auto; */
        padding-bottom: 2cm;
       
    }
}