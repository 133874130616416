
.root {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.text { 
    width: 100%;
    height: 50px;
    background: white;
    position: fixed;
    top:0;
    border-bottom: 1px solid grey;

    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-flow: row;
    justify-content: center;
}
@media (max-width:599px) {
    .container { flex-flow: column ;}
}

/* Body */

.human_try {
    width: 207px;
    height: 500px;
    display: block;
   
}

.part {
    fill: url(#spam_color);
    /* filter: url(#shadow); */
    -webkit-tap-highlight-color: transparent;
}
.partSelected {
    fill: url(#spam_selected);
    -webkit-tap-highlight-color: transparent;
}

.part:hover, 
.partSelected:hover {
    fill: url(#spam_hover);
    cursor: pointer;
}

.partSelectedLow {
    fill: url(#spam_low);
    cursor: pointer;
}
.partSelectedModerate {
    fill: url(#spam_moderate);
    cursor: pointer;
}
.partSelectedHigh {
    fill: url(#spam_high);
    cursor: pointer;
}
